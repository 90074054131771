import React from 'react';
import { css } from 'styled-components';
import { STREAMING_DATA } from 'views/audio-video/streaming-advantages-section/constants';
import { SectionTilesSection } from 'modules/section-tiles-section';

export const StreamingAdvantagesSection = () => {
    return (
        <SectionTilesSection
            title="audio-video-page.advantagesOfStreaming"
            textWrapperCss={css`
                max-width: 46.25rem;
            `}
            sectionTilesCss={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                column-gap: 5.2rem;
            `}
            data={STREAMING_DATA}
        />
    );
};
