import React from 'react';
import { STREAMING_BUSINESS_BENEFITS } from 'views/audio-video/streaming-business-benefits-section/constants';
import { AccordionContentSection } from 'modules/accordion-content-section';
import Streaming from 'svgs/audio-video/video-streaming.svg';

export const StreamingBenefitsSection = () => {
    return (
        <AccordionContentSection
            titleId="audio-video-page.industries"
            subtitleId="audio-video-page.streamingSolutionsBring"
            listData={STREAMING_BUSINESS_BENEFITS}
            img={<Streaming width="100%" height="100%" />}
            alt="Streaming Solutions"
        />
    );
};
