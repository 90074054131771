import React from 'react';
import { css } from 'styled-components';
import { AnimatedStreamingSolutions } from 'components/animated-streaming-solutions';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { MainSection } from 'modules/main-section';

export const AudioVideoMainSection = () => {
    return (
        <MainSection
            icon={<AnimatedStreamingSolutions />}
            iconWrapperCss={css`
                max-width: 33.125rem;
            `}
            titleId="audio-video-page.streamingSolutions"
            paragraphs={['audio-video-page.streamingLikeAPro']}
            estimateProjectAnalytics={
                gaTrackedEvents.AUDIO_VIDEO.CTA.ESTIMATE_PROJECT
            }
            intoCallAnalytics={gaTrackedEvents.AUDIO_VIDEO.CTA.INTRO_CALL}
        />
    );
};
