import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { node, string } from 'prop-types';

const SWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: var(--gray-color-90);
    padding: 0 1.125rem;
    height: 10rem;

    ${(props) => {
        return props.children?.length > 0
            ? css`
                  justify-content: space-around;
              `
            : css`
                  justify-content: center;
              `;
    }}

    ${CONSTANTS.MEDIA.max1200`
        height: 100%;
        flex-wrap: wrap;
        row-gap: 4.25rem;
        padding: 2.125rem 1.125rem;

        & > * {
            flex-basis: 50%;
            display: flex;
            justify-content: center;
        }
    `}

    ${CONSTANTS.MEDIA.max768`
        & > * { 
            flex-basis: 100%;
        }
    `}

    ${CONSTANTS.MEDIA.max500`
        margin: 2.25rem 0;
    `};

    ${CONSTANTS.MEDIA.max420`
        padding: 1.125rem;
    `}
`;

export const BadgesSection = ({ children }) => (
    <Container>
        <SWrapper>{children}</SWrapper>
    </Container>
);

BadgesSection.propTypes = {
    children: node.isRequired,
    href: string.isRequired,
    ariaLabel: string.isRequired,
};
