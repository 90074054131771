export const STREAMING_BUSINESS_BENEFITS = [
    {
        title: 'audio-video-page.retail',
        text: 'audio-video-page.retailDesc',
    },
    {
        title: 'audio-video-page.realEstate',
        text: 'audio-video-page.realEstateDesc',
    },
    {
        title: 'audio-video-page.webinars',
        text: 'audio-video-page.webinarsDesc',
    },
    {
        title: 'audio-video-page.healthcare',
        text: 'audio-video-page.healthcareDesc',
    },
];
