import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.ENTERTAINMENT_STREAMING],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'audio-video-page.footer.button',
    },
    categoryBanner: {
        title: 'audio-video-page.footer.title',
        description: 'audio-video-page.footer.description',
        svg: JavascriptSvg,
    },
};
