import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

export const useAudioVideoData = () => {
    const images = useStaticQuery(graphql`
        {
            musicDistribution: file(
                relativePath: {
                    eq: "case-studies/music-distribution-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            audioMusicMarketplace: file(
                relativePath: {
                    eq: "case-studies/audio-music-marketplace-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            airdance: file(
                relativePath: { eq: "case-studies/airdance-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            id: 'musicDistribution',
            title: 'case-studies-page.musicDistribution',
            field: 'case-studies-page.field.streaming',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.AUDIO_STREAMING,
            ],
            image: images.musicDistribution.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.MUSIC_DISTRIBUTION_SOFTWARE}`,
        },
        {
            id: 'audioMusicMarketplace',
            title: 'case-studies-page.audioMusicMarketplace',
            field: 'case-studies-page.field.streaming',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.AUDIO_STREAMING,
            ],
            image: images.audioMusicMarketplace.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.AUDIO_MUSIC_MARKETPLACE}`,
        },
        {
            id: 'airdance',
            title: 'case-studies-page.airdance',
            field: 'case-studies-page.field.streaming',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.VIDEO_STREAMING,
                CASE_STUDIES_TAG.LIVE_STREAMING,
            ],
            image: images.airdance.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.DANCE_LIVE_STREAMING_SOFTWARE}`,
        },
    ];
};
