import React from 'react';
import Brush from 'svgs/icons/ic-brush-with-border.svg';
import Like from 'svgs/icons/ic-like-message.svg';
import Messages from 'svgs/icons/ic-messages.svg';
import Video from 'svgs/icons/ic-video.svg';
import VideoPlay from 'svgs/icons/ic-video-play.svg';

export const STREAMING_DATA = [
    {
        icon: <VideoPlay width={42} height={42} />,
        text: 'audio-video-page.vod',
        description: 'audio-video-page.vodDesc',
    },
    {
        icon: <Like width={42} height={42} />,
        text: 'audio-video-page.evaluation',
        description: 'audio-video-page.evaluationDesc',
    },
    {
        icon: <Brush width={42} height={42} />,
        text: 'audio-video-page.interactivity',
        description: 'audio-video-page.interactivityDesc',
    },
    {
        icon: <Video width={42} height={42} />,
        text: 'audio-video-page.broadcasts',
        description: 'audio-video-page.broadcastsDesc',
    },
    {
        icon: <Messages width={42} height={42} />,
        text: 'audio-video-page.liveChat',
        description: 'audio-video-page.liveChatDesc',
    },
];
