import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import React from 'react';
import { useIntl } from 'react-intl';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';

const SWrapper = styled.div`
    background-color: var(--gray-color-90);
    padding: 6.25rem 13rem;

    ${CONSTANTS.MEDIA.max1200`
        padding: 4.25rem 7.5rem;     
    `}

    ${CONSTANTS.MEDIA.max1024`
        padding: 5rem 4.5rem; 
    `}

    ${CONSTANTS.MEDIA.max768`
        padding: 4rem 6.5rem; 
    `}

    ${CONSTANTS.MEDIA.max500`
        padding: 0;
    `}
`;

export const YoutubePlayer = ({ videoId, title }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <SWrapper>
                <LiteYouTubeEmbed
                    id={videoId}
                    title={formatMessage({
                        id: title,
                    })}
                />
            </SWrapper>
        </Container>
    );
};

YoutubePlayer.propTypes = {
    videoId: string.isRequired,
    title: string.isRequired,
};
