import React from 'react';
import { useAudioVideoData } from 'views/audio-video/streaming-case-studies-section/constant';
import { SeeAllCaseStudiesSection } from 'modules/see-all-case-studies-section';

export const StreamingCaseStudiesSection = () => {
    return (
        <SeeAllCaseStudiesSection
            title="caseStudies"
            description="exploreOurPastSuccess"
            data={useAudioVideoData()}
        />
    );
};
